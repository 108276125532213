
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PartnerRequest } from "@/store/modules/partner-request.store";
import { bus } from "@/main";
import type { CalendarAction } from "@/store/modules/appointment.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        AppointmentCard: () => import("@/components/appointments/card.vue"),
        AppointmentCreateModal: () => import("@/components/modals/create-appointment.vue"),
        AppointmentUpdateModal: () => import("@/components/modals/update-appointment.vue"),
        AppointmentDeleteModal: () => import("@/components/modals/delete-appointment.vue"),
    },
})
export default class RequestDocuments extends Vue {
    @Getter("appointment/all") appointments!: Appointment[];
    @Getter("appointment/actions") actions!: CalendarAction[];

    @Action("appointment/indexV1") indexAppointments!: AppointmentIndex;

    @Prop({ required: true }) partnerRequest!: PartnerRequest;

    bus = bus;

    loading = false;

    async mounted() {
        this.loading = true;

        await this.indexAppointments({ activity_id: this.partnerRequest.activity.id });

        this.loading = false;
    }
}
